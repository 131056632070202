<template>
  <div>
    <!--begin::Dashboard-->
    <div class="card card-custom p-5 gutter-b">
      <!--begin::Body-->
      <div class="card-body p-0">
        <div class="row">
          <div class="col-xxl">
            <!--begin::card-->
            <div class="card card-custom my-2 default-height pt-5 bg-warning">
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
                >
                  <span class="symbol  symbol-50 symbol-light-warning mr-2">
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-xl svg-icon-warning">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-4-blocks.svg"
                        />
                      </span>
                    </span>
                  </span>

                  <div class="d-flex flex-column text-right">
                    <span class="text-dark font-weight-bolder font-size-h2"
                      >75</span
                    >

                    <span class="text-white font-weight-bold mt-2"
                      >Project Count</span
                    >
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::card-->
          </div>

          <div class="col-xxl">
            <!--begin::card-->
            <div
              class="card card-custom my-2 default-height bg-light-success pt-5"
            >
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
                >
                  <span class="symbol  symbol-50 symbol-success mr-2">
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-xl svg-icon-dark">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-4-blocks.svg"
                        />
                      </span>
                    </span>
                  </span>

                  <div class="d-flex flex-column text-right">
                    <span class="text-dark font-weight-bolder font-size-h2"
                      >100</span
                    >

                    <span class="text-dark-50 font-weight-bold mt-2"
                      >DMP Realeased</span
                    >
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::card-->
          </div>

          <div class="col-xxl">
            <!--begin::card-->
            <div class="card card-custom my-2 default-height bg-success pt-5">
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
                >
                  <span class="symbol  symbol-50 symbol-light-success mr-2">
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-xl svg-icon-success">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-4-blocks.svg"
                        />
                      </span>
                    </span>
                  </span>

                  <div class="d-flex flex-column text-right">
                    <span class="text-dark font-weight-bolder font-size-h2"
                      >75</span
                    >

                    <span class="text-white font-weight-bold mt-2"
                      >Open Item MOM</span
                    >
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::card-->
          </div>

          <div class="col-xxl">
            <!--begin::card-->
            <div
              class="card card-custom my-2 default-height bg-light-primary pt-5"
            >
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
                >
                  <span class="symbol  symbol-50 symbol-primary mr-2">
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-xl svg-icon-dark">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-4-blocks.svg"
                        />
                      </span>
                    </span>
                  </span>

                  <div class="d-flex flex-column text-right">
                    <span class="text-dark font-weight-bolder font-size-h2"
                      >60</span
                    >

                    <span class="text-dark-50 font-weight-bold mt-2"
                      >Return Status</span
                    >
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::card-->
          </div>

          <div class="col-xxl">
            <!--begin::card-->
            <div class="card card-custom my-2 default-height bg-info pt-5">
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
                >
                  <span class="symbol  symbol-50 symbol-light-info mr-2">
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-xl svg-icon-info">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-4-blocks.svg"
                        />
                      </span>
                    </span>
                  </span>

                  <div class="d-flex flex-column text-right">
                    <span class="text-dark font-weight-bolder font-size-h2"
                      >55</span
                    >

                    <span class="text-white font-weight-bold mt-2"
                      >Upload Server</span
                    >
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::card-->
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>

    <AdvancedTableWidget3></AdvancedTableWidget3>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";

export default {
  name: "main-dashboard",
  components: {
    AdvancedTableWidget3
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Main Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>

<style scoped>
.default-height {
  height: 150px !important;
}
</style>
